<template>
    <div class="arg-choose-area">
        <el-dialog
            :visible.sync="visible"
            title="选择区域"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <el-select v-model="value" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-button type="primary" @click="getCode">生成企业码</el-button>
        </el-dialog>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
    data() {
        return {
            visible: false,
            options: [],
            value: '',
            row: '',
        };
    },
    computed: {},
    methods: {
        init(row) {
            this.visible = true;
            this.row = row;
            this.$nextTick(async () => {
                const { data: res } = await this.$http.get('/sys/region/list');
                const { data } = res;
                this.options = data;
                console.log(this.options);
            });
        },
        getCode: debounce(async function () {
            if (!this.value) return;
            const dataObj = {
                id: this.row.id,
                name: this.row.name,
                regionId: this.value,
            };
            await this.$http.post('/sys/org/create/orgIdentify', {
                ...dataObj,
            });
            this.visible = false;
            this.row = '';
            this.$emit('refreshDataList');
        }),
    },
};
</script>

<style lang="scss">
.arg-choose-area {
    .el-dialog__body {
        display: flex;
        justify-content: space-around;
    }
}
</style>
